import { Button, Card, Form, Input, message, Typography } from 'antd'
import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../hooks/useAuth'

const { Text } = Typography

const Wrapper = styled.section`
    display: flex;
    height: 100vh;
    width: 100vw;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgb(247, 247, 248);
`

const LogoWrapper = styled.div`
    display: flex;
    position: relative;
    height: 4em;
    width: 10em;
    justify-content: center;
    margin-right: 1em;
`

const StyledLink = styled.a`
    color: rgb(0, 102, 204);

    &:hover {
        text-decoration: underline;
    }
`

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

type FormValue = {
    email: string
    password: string
}

const LoginPage: NextPage = () => {
    const { signin, error, loading } = useAuth()

    const onFormFinish = async ({ email, password }: FormValue) => {
        await signin(email, password)
    }

    React.useEffect(() => {
        if (error) {
            message.error('Invalid email or password 😢')
        }
    }, [error])

    return (
        <>
            <Head>
                <title>Beta Login</title>
            </Head>
            <Wrapper>
                <LogoWrapper>
                    <Image
                        src="/images/logo.png"
                        layout="fill"
                        objectFit="contain"
                    />
                </LogoWrapper>
                <Card title="Login" style={{ minWidth: '50%' }}>
                    <Form onFinish={onFormFinish} layout="vertical">
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, type: 'email' }]}
                        >
                            <Input placeholder="abc@example.com" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Login
                            </Button>
                        </Form.Item>

                        <FooterWrapper>
                            <Text>
                                Do not have an account?{' '}
                                <Link href="/signup" passHref>
                                    <StyledLink>Signup</StyledLink>
                                </Link>
                            </Text>

                            <Text>
                                <Link href="/forget" passHref>
                                    <StyledLink>Forget password?</StyledLink>
                                </Link>
                            </Text>
                        </FooterWrapper>
                    </Form>
                </Card>
            </Wrapper>
        </>
    )
}

export default LoginPage
